import React, {
  useState,
  useEffect,
  MouseEvent,
  useRef,
  useCallback,
} from 'react'
import { Vector2 } from '../math/Vector2'

type Props = {
  setOffset: (callback: (prevOffset: Vector2) => Vector2) => void
  setZoomLevel: (callback: (prevZoomLevel: number) => number) => void
  children: React.ReactNode
}

const DraggableComponent = ({ setOffset, setZoomLevel, children }: Props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [lastMousePos, setLastMousePos] = useState(Vector2.zero())

  const componentRef = useRef<HTMLDivElement>(null)

  // Drag events

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setLastMousePos(new Vector2(e.clientX, e.clientY))
    setIsDragging(true)
  }

  const handleMouseMove = useCallback(
    (e: any): void => {
      if (isDragging) {
        let currentPos = new Vector2(e.clientX, e.clientY)
        setOffset((prevOffset) => {
          let newOffset = prevOffset.clone().add(currentPos).sub(lastMousePos)
          return newOffset
        })
        setLastMousePos(currentPos)
      }
    },
    [lastMousePos, isDragging, setOffset]
  )

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, handleMouseMove])

  // Zoom events

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault()

    const target = e.target as HTMLDivElement
    let currentPos = new Vector2(
      e.clientX - target.offsetLeft,
      e.clientY - target.offsetTop
    )

    setZoomLevel((prevZoom) => {
      const factor = 1.05
      const newZoom =
        e.deltaY > 0
          ? Math.max(prevZoom / factor, 0.1)
          : Math.min(prevZoom * factor, 100)

      setOffset((prevOffset) =>
        currentPos
          .clone()
          .addScaledVector(
            currentPos.clone().sub(prevOffset),
            -newZoom / prevZoom
          )
      )

      return newZoom
    })
  }

  useEffect(() => {
    const component = componentRef.current
    if (component != null) {
      component.addEventListener('wheel', handleWheel)
      return () => {
        component.removeEventListener('wheel', handleWheel)
      }
    }
  }, [componentRef])

  return (
    <div className="draggable" onMouseDown={handleMouseDown} ref={componentRef}>
      {children}
    </div>
  )
}

export default DraggableComponent
