import { Transform } from './Transform'
import { Vector2 } from './Vector2'

export class Segment2 {
  p0: Vector2
  p1: Vector2

  constructor(p0: Vector2, p1: Vector2) {
    this.p0 = p0
    this.p1 = p1
  }

  getLength(): number {
    return this.p0.distanceTo(this.p1)
  }

  transform(t: Transform): Segment2 {
    return new Segment2(
      this.p0.clone().transform(t),
      this.p1.clone().transform(t)
    )
  }
}
