import { Line2 } from './Line2'
import { Segment2 } from './Segment2'
import { Vector2 } from './Vector2'

export interface AbstractLine {
  flatMapToLine(): Line2
  getHandles(): Segment2[]
}

export class LineGroup {
  lines: AbstractLine[]

  constructor(lines: AbstractLine[]) {
    this.lines = lines
  }
}
