import { BezierCurve2 } from './BezierCurve2'
import { Line2 } from './Line2'
import { AbstractLine } from './LineGroup'
import { Segment2 } from './Segment2'

export class BezierLine2 implements AbstractLine {
  segments: BezierCurve2[]

  constructor(segments: BezierCurve2[]) {
    this.segments = segments

    for (let i = 0; i < this.segments.length - 1; i++) {
      console.assert(
        this.segments[i].p3.equals(this.segments[i + 1].p0),
        'Segments should be connected'
      )
    }
  }

  flatMapToLine(): Line2 {
    let points = []
    for (const segment of this.segments) {
      const subdivision = 50
      for (let i = 0; i <= subdivision; i++) {
        let t = i / subdivision
        points.push(segment.sampleAt(t))
      }
    }
    return new Line2(points)
  }

  getHandles(): Segment2[] {
    return this.segments.flatMap((segment) => [
      new Segment2(segment.p0, segment.p1),
      new Segment2(segment.p3, segment.p2),
    ])
  }
}
