import { Vector2 } from './Vector2'

export class Transform {
  preAdd: Vector2
  multiply: Vector2
  postAdd: Vector2

  constructor(preAdd: Vector2, multiply: Vector2, postAdd: Vector2) {
    this.preAdd = preAdd
    this.multiply = multiply
    this.postAdd = postAdd
  }

  inverted(): Transform {
    return new Transform(
      this.postAdd.clone().negate(),
      new Vector2(1 / this.multiply.x, 1 / this.multiply.y),
      this.preAdd.clone().negate()
    )
  }
}
