import { Vector2 } from './Vector2'

export class BezierCurve2 {
  p0: Vector2
  p1: Vector2
  p2: Vector2
  p3: Vector2

  constructor(p0: Vector2, p1: Vector2, p2: Vector2, p3: Vector2) {
    this.p0 = p0
    this.p1 = p1
    this.p2 = p2
    this.p3 = p3
  }

  sampleAt(t: number): Vector2 {
    const s = 1.0 - t
    const t2 = t * t
    const s2 = s * s

    return this.p0
      .clone()
      .multiplyScalar(s2 * s)
      .addScaledVector(this.p1, 3.0 * s2 * t)
      .addScaledVector(this.p2, 3.0 * s * t2)
      .addScaledVector(this.p3, t2 * t)
  }
}
