import { Transform } from '../math/Transform'
import { Vector2 } from '../math/Vector2'

export function renderGrid(
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  dataToCanvasTransform: Transform
) {
  const canvasToDataTransform = dataToCanvasTransform.inverted()

  const min = new Vector2(0, height).transform(canvasToDataTransform)
  const max = new Vector2(width, 0).transform(canvasToDataTransform)
  const size = max.clone().sub(min)

  const minSize = Math.min(Math.abs(size.x), Math.abs(size.y)) / 2

  const maxSizeLog10 = Math.log10(minSize)
  const gridSize1 = Math.pow(10, Math.ceil(maxSizeLog10))
  const gridSize2 = gridSize1 / 10

  const gridStart = new Vector2(
    Math.floor(min.x / gridSize1) * gridSize1,
    Math.floor(min.y / gridSize1) * gridSize1
  )

  //console.log(min, max, size, 'grid', gridSize1, gridStart)

  const makeVerticalLine = (pos: number): [Vector2, Vector2] => {
    const p = new Vector2(pos, 0).transform(dataToCanvasTransform)
    return [new Vector2(p.x, 0), new Vector2(p.x, height)]
  }

  const makeHorizontalLine = (pos: number): [Vector2, Vector2] => {
    const p = new Vector2(0, pos).transform(dataToCanvasTransform)
    return [new Vector2(0, p.y), new Vector2(width, p.y)]
  }

  context.setLineDash([12, 2])
  context.strokeStyle = '#444444'
  const alphaFactor = maxSizeLog10 - Math.floor(maxSizeLog10)
  context.globalAlpha = 0.6 * alphaFactor
  context.font = '14px serif'

  const textOffsetVertical = new Vector2(3, 15)
  const textOffsetHorizontal = new Vector2(5, -3)
  renderGridLines(
    context,
    gridStart.x,
    gridSize1,
    max.x,
    textOffsetVertical,
    makeVerticalLine
  )
  renderGridLines(
    context,
    gridStart.y,
    gridSize1,
    max.y,
    textOffsetHorizontal,
    makeHorizontalLine
  )

  context.globalAlpha = 0.6 * (1 - alphaFactor)

  renderGridLines(
    context,
    gridStart.x,
    gridSize2,
    max.x,
    textOffsetVertical,
    makeVerticalLine
  )
  renderGridLines(
    context,
    gridStart.y,
    gridSize2,
    max.y,
    textOffsetHorizontal,
    makeHorizontalLine
  )
}

function renderGridLines(
  context: CanvasRenderingContext2D,
  gridStart: number,
  gridSize: number,
  max: number,
  textOffset: Vector2,
  makeLine: (pos: number) => [Vector2, Vector2]
) {
  console.assert(gridStart < max)
  for (let pos = gridStart; pos < max; pos += gridSize) {
    let line = makeLine(pos)

    context.beginPath()
    context.moveTo(line[0].x, line[0].y)
    context.lineTo(line[1].x, line[1].y)
    context.stroke()

    const textPos = line[0].clone().add(textOffset)

    const str = pos.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    })
    context.fillText(str, textPos.x, textPos.y)
  }
}
