import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LineGroup } from '../math/LineGroup'
import { Vector2 } from '../math/Vector2'
import { Line2 } from '../math/Line2'
import { Segment2 } from '../math/Segment2'
import DraggableComponent from './DraggableComponent'
import { Transform } from '../math/Transform'
import { renderGrid as renderGridLines } from './Grid'

type ColoredLineGroup = {
  lineGroup: LineGroup
  color: string
}

export type RenderingSettings = {
  renderHandles: boolean
  renderGrid: boolean
  offset: Vector2
  setOffset: (callback: (prevOffset: Vector2) => Vector2) => void
  zoomLevel: number
  setZoomLevel: (callback: (prevZoomLevel: number) => number) => void
}

type Props = {
  settintgs: RenderingSettings
  groups: ColoredLineGroup[]
}

function Canvas({ settintgs, groups }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const repaint = useCallback(() => {
    const canvas = canvasRef.current
    if (canvas != null) {
      render(
        canvas,
        groups,
        settintgs.renderHandles,
        settintgs.renderGrid,
        settintgs.offset,
        settintgs.zoomLevel
      )
    } else {
      console.error('Failed to get canvas element')
    }
  }, [groups, settintgs])

  useEffect(() => {
    repaint()
  }, [repaint])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(repaint)

    if (canvasRef.current) {
      resizeObserver.observe(canvasRef.current)
    }

    // Cleanup on component unmount
    return () => {
      if (canvasRef.current) {
        resizeObserver.unobserve(canvasRef.current)
      }
    }
  }, [repaint])

  return (
    <div className="outputCanvas">
      <DraggableComponent
        setOffset={settintgs.setOffset}
        setZoomLevel={settintgs.setZoomLevel}
      >
        <canvas ref={canvasRef} />
      </DraggableComponent>
    </div>
  )
}

export default Canvas

class RenderingTransform {
  preAdd: Vector2
  scale: Vector2

  constructor(min: Vector2, max: Vector2, canvas: HTMLCanvasElement) {
    const padding = 10
    const w = canvas.width - padding * 2
    const h = canvas.height - padding * 2

    const size = max.clone().sub(min)
    if (size.x === 0 && size.y === 0) {
      size.x = size.y = 1
    }

    const minScale = Math.min(w / size.x, h / size.y)
    this.scale = new Vector2(minScale, -minScale)

    //const offsetY = size.y < 0 ?  : 0
    console.log(minScale)

    this.preAdd = min
      .clone()
      .negate()
      .add(new Vector2(0, (-size.y - h / minScale) / 2))
      .addScaledVector(new Vector2(padding, -padding), 1 / minScale)
  }
}

function calculateRenderingTransform(
  groups: LineGroup[],
  canvas: HTMLCanvasElement
) {
  let min = null,
    max = null

  for (const lg of groups) {
    for (const l of lg.lines) {
      const line = l.flatMapToLine()
      const bounds = line.getBounds()

      if (bounds != null) {
        if (min == null) {
          min = bounds.min
        } else {
          min = min.min(bounds.min)
        }

        if (max == null) {
          max = bounds.max
        } else {
          max = max.max(bounds.max)
        }
      }
    }
  }

  if (min == null || max == null) {
    min = new Vector2(0, 0)
    max = new Vector2(1, 1)
  }

  return new RenderingTransform(min, max, canvas)
}

function drawLine(
  context: CanvasRenderingContext2D,
  line: Line2,
  color: string
) {
  context.globalAlpha = 1
  context.setLineDash([])
  context.strokeStyle = color

  context.beginPath()
  context.moveTo(line.points[0].x, line.points[0].y)
  for (var i = 1; i < line.points.length; ++i) {
    context.lineTo(line.points[i].x, line.points[i].y)
  }
  context.stroke()
}

function drawHandle(
  context: CanvasRenderingContext2D,
  handle: Segment2,
  color: string
) {
  context.globalAlpha = 0.6
  context.setLineDash([5, 2])
  context.strokeStyle = color

  const nodeSize = 10
  context.rect(
    handle.p0.x - nodeSize / 2,
    handle.p0.y - nodeSize / 2,
    nodeSize,
    nodeSize
  )
  context.stroke()

  if (handle.getLength() > 0) {
    context.beginPath()
    context.moveTo(handle.p0.x, handle.p0.y)
    context.lineTo(handle.p1.x, handle.p1.y)
    context.stroke()

    const nodeSize = 10
    context.beginPath()
    context.arc(handle.p1.x, handle.p1.y, nodeSize / 2, 0, Math.PI * 2)
    context.fillStyle = color
    context.fill()
  }
}

function render(
  canvas: HTMLCanvasElement,
  groups: ColoredLineGroup[],
  renderHandles: boolean,
  renderGrid: boolean,
  offset: Vector2,
  zoomLevel: number
) {
  canvas.width = canvas.clientWidth
  canvas.height = canvas.clientHeight
  const context = canvas.getContext('2d')
  if (context == null) {
    console.error('Failed to get 2d rendering context')
    return
  }

  context.clearRect(0, 0, canvas.width, canvas.height)
  //console.log("canvas", canvas.width, canvas.height, canvas.clientWidth, canvas.clientHeight)

  const renderingTransform = calculateRenderingTransform(
    groups.map((group) => group.lineGroup),
    canvas
  )

  const dataToCanvasTransform = new Transform(
    renderingTransform.preAdd,
    renderingTransform.scale.clone().multiplyScalar(zoomLevel),
    offset
  )

  if (renderGrid) {
    renderGridLines(context, canvas.width, canvas.height, dataToCanvasTransform)
  }

  for (const lg of groups) {
    const col = lg.color
    for (const l of lg.lineGroup.lines) {
      const line = l.flatMapToLine()
      const localLine = line.transform(dataToCanvasTransform)
      drawLine(context, localLine, col)

      if (renderHandles) {
        for (const handle of l.getHandles()) {
          drawHandle(context, handle.transform(dataToCanvasTransform), col)
        }
      }
    }
  }
}
