import React from 'react'

type Props = {
  title: string | null
  input: string
  isValid: boolean
  lineColor: string
  edit: (input: string) => void
  remove: () => void
  changeColor: () => void
}

function LineGroupInput({
  title,
  input,
  isValid,
  lineColor,
  edit,
  remove,
  changeColor,
}: Props) {
  return (
    <div className={`lineGroupInput ${isValid ? '' : 'invalid'}`}>
      {title && <div className="title">{title}</div>}
      <textarea
        rows={10}
        cols={40}
        onInput={(e) => edit((e.target as HTMLTextAreaElement).value)}
        value={input}
      />{' '}
      <br />
      <div className="bottom-row">
        <button className="button-remove" onClick={remove}>
          -
        </button>
        <div
          className="color"
          style={{ backgroundColor: lineColor }}
          onClick={changeColor}
        />
      </div>
    </div>
  )
}

export default LineGroupInput
