import { Transform } from './Transform'

export class Vector2 {
  static zero(): Vector2 {
    return new Vector2(0, 0)
  }

  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  clone() {
    return new Vector2(this.x, this.y)
  }

  sub(p: Vector2) {
    this.x -= p.x
    this.y -= p.y
    return this
  }

  add(p: Vector2) {
    this.x += p.x
    this.y += p.y
    return this
  }

  addScaledVector(p: Vector2, f: number) {
    this.x += p.x * f
    this.y += p.y * f
    return this
  }

  multiplyScalar(f: number) {
    this.x *= f
    this.y *= f
    return this
  }

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y)
  }

  dot(p: Vector2) {
    return this.x * p.x + this.y * p.y
  }

  negate() {
    this.x = -this.x
    this.y = -this.y
    return this
  }

  normalize() {
    var l = this.length()
    this.x /= l
    this.y /= l
    return this
  }

  min(p: Vector2): Vector2 {
    this.x = Math.min(this.x, p.x)
    this.y = Math.min(this.y, p.y)
    return this
  }

  max(p: Vector2): Vector2 {
    this.x = Math.max(this.x, p.x)
    this.y = Math.max(this.y, p.y)
    return this
  }

  equals(p: Vector2) {
    return this.x === p.x && this.y === p.y
  }

  distanceTo(p: Vector2) {
    const dx = this.x - p.x
    const dy = this.y - p.y
    return Math.sqrt(dx * dx + dy * dy)
  }

  transform(t: Transform): Vector2 {
    this.x = (this.x + t.preAdd.x) * t.multiply.x + t.postAdd.x
    this.y = (this.y + t.preAdd.y) * t.multiply.y + t.postAdd.y
    return this
  }
}
