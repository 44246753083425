import { BezierCurve2 } from '../math/BezierCurve2'
import { BezierLine2 } from '../math/BezierLine2'
import { Line2 } from '../math/Line2'
import { LineGroup } from '../math/LineGroup'
import { Vector2 } from '../math/Vector2'

export class Parser {
  static parse(text: string): LineGroup | null {
    try {
      const result = JSONParser.parse(text)
      if (result != null) {
        return result
      }

      return TextParser.parseText(text)
    } catch (ex) {
      console.warn('Faile to parse', ex)
      return null
    }
  }
}

class JSONParser {
  static parse(text: string): LineGroup | null {
    let json = null
    try {
      json = JSON.parse(text)
    } catch (ex) {
      return null
    }

    enum ParserLevel {
      array,
      multiLineString,
      lineString,
      objectXY,
    }

    const levels: ParserLevel[] = []

    const fillLevel = (obj: any) => {
      if (Array.isArray(obj)) {
        if (obj.length > 0) {
          levels.push(ParserLevel.array)
          fillLevel(obj[0])
        }
      } else if (typeof obj === 'object') {
        if (obj.type === 'MultiLineString') {
          levels.push(ParserLevel.multiLineString)
        } else if (obj.type === 'LineString') {
          levels.push(ParserLevel.lineString)
        } else if ('x' in obj && 'y' in obj) {
          levels.push(ParserLevel.objectXY)
        }
      }
    }
    fillLevel(json)

    const match = (levels1: ParserLevel[], levels2: ParserLevel[]) => {
      return levels1.join('|') === levels2.join('|')
    }

    const parse2D = (data: any) => {
      return new Line2(
        data.map((p: any) => {
          if (Array.isArray(p)) {
            console.assert(p.length >= 2)
            return new Vector2(p[0], p[1])
          } else if (typeof p === 'object') {
            const { x, y } = p
            console.assert(x != null && y != null)
            return new Vector2(x, y)
          } else {
            throw 'Unknown type'
          }
        })
      )
    }

    const parse3D = (data: any) => {
      return new LineGroup(data.map((l: any) => parse2D(l)))
    }

    if (match(levels, [ParserLevel.multiLineString])) {
      return parse3D(json.coordinates)
    } else if (match(levels, [ParserLevel.lineString])) {
      return new LineGroup([parse2D(json.coordinates)])
    } else if (
      match(levels, [ParserLevel.array, ParserLevel.array]) ||
      match(levels, [ParserLevel.array, ParserLevel.objectXY])
    ) {
      return new LineGroup([parse2D(json)])
    } else {
      console.error('Unregonized format', levels, json, text)
      return null
    }
  }
}

class TextParser {
  static parseText(text: string): LineGroup | null {
    return this.parseCGPath(text)
  }

  private static parseCGPath(text: string): LineGroup | null {
    const regexCommand = /^(MoveTo|CurveTo|QuadCurveTo|LineTo|Close)/
    const regexCoordinates = /\((-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\)/g

    const textLines = text.split('\n')
    const parsedCommands: { command: string; points: Vector2[] }[] = []

    textLines.forEach((line) => {
      const commandMatch = line.match(regexCommand)
      if (commandMatch) {
        const command = commandMatch[0]
        const points = []
        let match
        while ((match = regexCoordinates.exec(line)) !== null) {
          const x = parseFloat(match[1])
          const y = parseFloat(match[3])
          points.push(new Vector2(x, y))
        }
        parsedCommands.push({ command, points })
      }
    })

    if (parsedCommands.length === 0) {
      return null
    }

    let bezierLines: BezierLine2[] = []
    let lastPoint: Vector2 | null = null
    let segments: BezierCurve2[] = []
    for (const parsedCommand of parsedCommands) {
      const { command, points } = parsedCommand
      if (command === 'MoveTo') {
        console.assert(points.length === 1)

        if (segments.length > 0) {
          bezierLines.push(new BezierLine2(segments))
          segments = []
        }

        lastPoint = points[0]
      } else {
        if (lastPoint == null) {
          console.error('Last point should be defined')
          continue
        }

        if (command === 'Close') {
          console.assert(points.length === 0)

          console.assert(segments.length > 0)
          const firstPoint = segments[0].p0
          segments.push(
            new BezierCurve2(lastPoint, lastPoint, firstPoint, firstPoint)
          )

          lastPoint = null
        } else {
          if (command === 'CurveTo') {
            console.assert(points.length === 3)

            segments.push(
              new BezierCurve2(lastPoint, points[0], points[1], points[2])
            )
          } else if (command === 'QuadCurveTo') {
            console.assert(points.length === 2)

            let outPoint = lastPoint
              .clone()
              .addScaledVector(points[0].clone().sub(lastPoint), 2.0 / 3)
            let inPoint = points[1]
              .clone()
              .addScaledVector(points[0].clone().sub(points[1]), 2.0 / 3)

            segments.push(
              new BezierCurve2(lastPoint, outPoint, inPoint, points[2])
            )
          } else if (command === 'LineTo') {
            console.assert(points.length === 1)

            segments.push(
              new BezierCurve2(lastPoint, lastPoint, points[0], points[0])
            )
          } else {
            console.error('Unknown command', command)
          }

          lastPoint = points[points.length - 1]
        }
      }
    }

    if (segments.length > 0) {
      bezierLines.push(new BezierLine2(segments))
    }

    return new LineGroup(bezierLines)
  }
}
