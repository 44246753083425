import React, { useEffect, useState } from 'react'
import './App.css'
import LineGroupInput from './components/LineGroupInput'
import Canvas from './components/Canvas'
import { LineGroup } from './math/LineGroup'
import { Parser } from './misc/Parser'
import { Random } from './math/Random'
import { supportedFormats } from './FormatSamples'
import { Vector2 } from './math/Vector2'
import LabeledCheckbox from './components/LabeledCheckbox'

type LineGroupItem = {
  title: string | null
  input: string
  group: LineGroup | null
  color: string
}

function App() {
  const [renderHandles, setRenderHandles] = useState<boolean>(true)
  const [renderGrid, setRenderGrid] = useState<boolean>(true)
  const [offset, setOffset] = useState(Vector2.zero())
  const [zoomLevel, setZoomLevel] = useState<number>(1)
  const [lineGroups, setLineGroups] = useState<LineGroupItem[]>([
    //{ input: '', group: null, color: getRandomColor() },
  ])

  useEffect(() => {
    setLineGroups(
      supportedFormats.map((format) => {
        let text =
          typeof format.sample == 'string'
            ? format.sample
            : JSON.stringify(format.sample)
        return {
          title: format.name,
          input: text,
          group: Parser.parse(text),
          color: getRandomColor(),
        }
      })
    )
  }, [])

  const addLineGroup = () => {
    setLineGroups([
      ...lineGroups,
      { title: null, input: '', group: null, color: getRandomColor() },
    ])
  }
  const removeLineGroup = (index: number) => {
    const newLineGroups = [
      ...lineGroups.slice(0, index),
      ...lineGroups.slice(index + 1),
    ]
    setLineGroups(newLineGroups)
  }
  const editLineGroup = (index: number, input: string) => {
    const newLineGroups = [...lineGroups]
    newLineGroups[index] = {
      ...newLineGroups[index],
      input: input,
      group: Parser.parse(input),
    }
    setLineGroups(newLineGroups)
  }
  const changeColor = (index: number) => {
    const newLineGroups = [...lineGroups]
    newLineGroups[index] = {
      ...newLineGroups[index],
      color: getRandomColor(),
    }
    setLineGroups(newLineGroups)
  }
  const clearAll = () => {
    setLineGroups([
      {
        title: null,
        input: '',
        group: null,
        color: lineGroups[0]?.color ?? getRandomColor(),
      },
    ])
  }

  const hasDemoTitle = lineGroups.some((lineGroup) => lineGroup.title != null)

  //console.log(lineGroups)

  return (
    <>
      <div className="mainLayout">
        <div className="inputPanel">
          <div className="settings">
            <div className="title">Display settings</div>
            <LabeledCheckbox
              title="Handles"
              value={renderHandles}
              setValue={setRenderHandles}
            />
            <LabeledCheckbox
              title="Grid"
              value={renderGrid}
              setValue={setRenderGrid}
            />
            <div className="sub-title">View</div>
            <button
              className="clear-all"
              onClick={() => {
                setOffset(Vector2.zero)
                setZoomLevel(1)
              }}
            >
              Zoom to fit
            </button>
          </div>
          <div className="inputs-title">
            <div>{hasDemoTitle ? 'Supported formats:' : 'Inputs:'}</div>
            <button className="clear-all" onClick={clearAll}>
              Clear all
            </button>
          </div>
          {lineGroups.map((lineGroup, index) => (
            <LineGroupInput
              key={index}
              title={lineGroup.title}
              input={lineGroup.input}
              isValid={lineGroup.input.trim() == '' || lineGroup.group != null}
              lineColor={lineGroup.color}
              edit={(input) => editLineGroup(index, input)}
              remove={() => {
                removeLineGroup(index)
              }}
              changeColor={() => changeColor(index)}
            />
          ))}
          <button className="buttonAdd" onClick={addLineGroup}>
            +
          </button>
        </div>
        <Canvas
          settintgs={{
            renderHandles,
            renderGrid,
            offset,
            setOffset,
            zoomLevel,
            setZoomLevel,
          }}
          groups={lineGroups.flatMap((groupItem) =>
            groupItem.group != null
              ? [{ lineGroup: groupItem.group, color: groupItem.color }]
              : []
          )}
        />
      </div>
      <div className="info-text">
        Do you need some other format? Let us know: info@plot-line.xyz
      </div>
    </>
  )
}

export default App

let numberRandom = new Random(3)

function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(numberRandom.nextFloat() * 16)]
  }
  return color
}
