export const supportedFormats = [
  {
    name: 'CGPath (debugDescription)',
    sample: `
MoveTo (30, 40)
CurveTo (30, 58.281) (30, 81.719) (30, 100)
CurveTo (71.895, 100) (142.5, 100) (180, 100)
CurveTo (185.509, 100) (190, 95.527) (190, 90)
CurveTo (190, 80) (190, 60) (190, 50)
CurveTo (190, 44.491) (185.527, 40) (180, 40)  
LineTo (30, 40)
`,
  },
  {
    name: 'JSON 2D array',
    sample: [
      [20, 90],
      [150, 110],
      [140, 80],
    ],
  },
  {
    name: 'JSON array of point objects',
    sample: [
      { x: 10, y: 20 },
      { x: 20, y: 50 },
    ],
  },
  {
    name: 'GeoJSON LineString',
    sample: {
      type: 'LineString',
      coordinates: [
        [130, 10],
        [140, 30],
        [60, 70],
      ],
    },
  },
  {
    name: 'GeoJSON MultiLineString',
    sample: {
      type: 'MultiLineString',
      coordinates: [
        [
          [70, 10],
          [60, 20],
          [50, 50],
        ],
        [
          [90, 50],
          [80, 30],
          [90, 20],
          [80, 10],
        ],
      ],
    },
  },
]
