import { Vector2 } from './Vector2'
import { AbstractLine } from './LineGroup'
import { Segment2 } from './Segment2'
import { Transform } from './Transform'

export class Line2 implements AbstractLine {
  points: Vector2[]

  constructor(points: Vector2[]) {
    console.assert(points.length > 0)
    this.points = points
  }

  flatMapToLine(): Line2 {
    return this
  }

  getHandles(): Segment2[] {
    return this.points.map((point) => new Segment2(point, point))
  }

  transform(t: Transform): Line2 {
    var res = []
    for (var i = 0; i < this.points.length; ++i) {
      res.push(this.points[i].clone().transform(t))
    }

    return new Line2(res)
  }

  getBounds(): { min: Vector2; max: Vector2 } | null {
    var min = new Vector2(Infinity, Infinity)
    var max = new Vector2(-Infinity, -Infinity)
    for (var i = 0; i < this.points.length; ++i) {
      min.min(this.points[i])
      max.max(this.points[i])
    }
    return { min, max }
  }
}
